import { Show, Spacer, ListIcon, List, ListItem } from "@chakra-ui/react";
import Stack from "../shared/Stack";
import { Body1, Caption1 } from "../shared/typography";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Card from "../shared/Card";

interface LeftPanelProps {
  editable?: boolean;
}

const LeftPanel: React.FC<LeftPanelProps> = (props) => {
  return (
    <Show above="lg">
      <Card
        alignItems="inherit"
        // backgroundColor={"#0ab1bc"}
        bgGradient="linear(0deg, rgba(2,0,36,1) 0%, rgba(2,118,137,1) 28%, rgba(2,177,188,1) 42%, rgba(2,186,205,1) 76%, rgba(0,212,255,1) 100%)"
        w="full"
        // w={{
        //     base: "full",
        //     lg: "266px",
        // }}
        spacing={4}
        py="8"
        pl="8"
        pr="8"
        pos="relative"
      >
        <Spacer />
        <Stack
          h="100%"
          w="full"
          direction="column"
          display="flex"
          justifyContent="center"
          alignItems="center"
          alignSelf="center"
        >
          <Stack direction="row" w="full" paddingInlineStart={"40px"}>
            <Body1
              margin="auto"
              fontSize={"21px"}
              mb="20px"
              fontFamily={"body"}
              bgGradient="radial(gray.300, yellow.100, pink.200)"
              bgClip="text"
              fontWeight={"bold"}
            >
              Welcome To Your Professional Networking and Profile Monetization
              Journey
            </Body1>
          </Stack>
          <List color="white" fontWeight={"bold"}>
            <ListItem>
              <ListIcon as={AiOutlineCheckCircle} />
              Unleash the Power Professional Profile Monetization.
            </ListItem>
            <ListItem>
              <ListIcon as={AiOutlineCheckCircle} />
              Network with Like-minded Professionals.
            </ListItem>
            <ListItem>
              <ListIcon as={AiOutlineCheckCircle} />
              Showcase Your Expertise with a Verified Profile.
            </ListItem>
            <ListItem>
              <ListIcon as={AiOutlineCheckCircle} />
              Stand Out and Make an Impact.
            </ListItem>
          </List>
        </Stack>
      </Card>
    </Show>
  );
};
export default LeftPanel;
