import Card from "@/components/shared/Card";
import Container from "@/components/shared/Container";
import Stack from "@/components/shared/Stack";
import { StackDivider } from "@chakra-ui/react";
import { NextPageWithLayout } from "types/Page";
import LoginForm from "@/components/login/LoginForm";
import { publicLayout } from "@/components/shared/Layout";
import LeftPanel from "@/components/login/LeftPanel";

interface LoginProps {

}


const Login: NextPageWithLayout<LoginProps> = (props) => {

    return (
        <>
            <Container>
                <Stack
                    my={{
                        base: 0,
                        lg: 6,
                    }}
                >
                    <LeftPanel/>
                    <Card
                        align="stretch"
                        //w="full"
                        w={{
                            base: "full",
                            lg: "70%",
                        }}
                        py="8"
                        spacing={8}
                        justifyContent={"center"}
                        divider={<StackDivider />}
                    >
                        <Stack
                            w="80%"
                            alignSelf={"center"}
                            rounded={'xl'}
                            p={{ base: 4, sm: 6, md: 6 }}
                            spacing={{ base: 8 }}
                            boxShadow="dark-lg"
                        >
                            <LoginForm />
                        </Stack>
                    </Card>
                </Stack>
            </Container>
        </>
    );
};

Login.getLayout = publicLayout;

export default Login;