import * as Yup from "yup";

export const loginValidation = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email address")
        .required("Please enter the email address"),
    password: Yup.string()
        .required("Please enter the password"),
});

export const forgotPasswordValidation = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email address")
        .required("Please enter email"),
});

export const employeeRecoveryValidation = Yup.object().shape({
    password: Yup.string()
        .required('Password is required')
        .min(5, 'Your password is too short.'),
    confirmPassword: Yup.string().when('password', (password, field) => password ? field.required('Please retype your password.')
        .oneOf([Yup.ref('password')], 'Your password do not match.') : field
    ),
});
