import { httpClient } from "./HttpInterceptor";

const authService = {
  AuthenticateUser: async (data: any) =>
    await httpClient.post("/api/user-login/authenticateUser", data)
      .then((response) => response)
    .catch((error) => error.response),
};

export default authService;
