import { FC, useState } from "react";
import { HandleSubmit } from "../shared/form-stepper";
import Stack from "../shared/Stack";
import { VStack, InputRightElement, InputGroup, useToast, AlertStatus } from "@chakra-ui/react";
import { Body1, Caption2 } from "../shared/typography";
import { Field, FieldProps, Form, Formik } from "formik";
import Input from "../shared/form-control/Input";
import { useStyles } from "@/core/form-error";
import ButtonPrimary from "../shared/button/ButtonPrimary";
import ErrorMessageYup from "../shared/error-message/ErrorMessageWrapper";
import Link from "../shared/Link";
import Logo from "../shared/Logo";
import { homePath } from "data/routes";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import PageLoader from "../shared/page-loader/PageLoader";
import { loginValidation } from "@/core/form-validaation/login";
import authService from "../services/AuthService";
import { CandidateProfile, PrivateProfile, SocialProfile } from "@/core/models/candidate";
import { useRouter } from "next/router";
import { encryptAes } from "@/core/utility/common";
import ForgotPasswordModal from "../shared/modal/ForgotPasswordModal";
import { useModalContext } from "hooks/useContext";
import { ParsedUrlQueryInput } from "querystring";
import { UserWalletRole } from "@prisma/client";
import IdentityVerificationModal from "../shared/modal/IdentityVerificationModal";


interface LoginFormProps {
    doneStep?: boolean;
    handleSubmit?: HandleSubmit<any>;
    myType?: number;
    referralCode?: string;
}


const LoginForm: FC<LoginFormProps> = (props) => {

    type LoginModel = {
        email: string,
        password: string,
    };
    const [initialValues, setInitialValues] = useState({} as LoginModel);

    const [userHome, setUserHome] = useState(homePath.root);
    const [showPassword, setShowPassword] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const toast = useToast();
    const router = useRouter();
    const { forgotPasswordModal, identityVerificationModal } = useModalContext();
    const { onOpen: onOpen } = forgotPasswordModal;


    const showToast = (title: string, status: AlertStatus) => {
        toast({
            title: title,
            status: status,
            duration: 3000,
            position: 'bottom-right',
            variant: 'left-accent',
            isClosable: true
        });
    };

    const pushRouterPath = (pathname: string, query: string | ParsedUrlQueryInput | null | undefined) => {
        router.push({
            pathname: pathname,
            query: query
        });
    };

    const handleSubmit: HandleSubmit<LoginModel> = async (values: LoginModel) => {

        if (!values) return;

        let model = {
            email: values.email,
            password: (values && values.password) ? await encryptAes(values.password, values.email) : ""
        } as LoginModel;

        setShowSpinner(true);

        let resp = await authService.AuthenticateUser(model);
        if (resp && resp?.status == 200) {
            const userDetails = resp.data as UserWalletRole;
            var today = new Date();
            var date = `${today.getFullYear()}-${today.getMonth()}-${today.getDate()}`;
            const paramsObj = Buffer.from(`${userDetails?.UId?.toString()}-${date}`).toString('base64');


            if (userDetails?.RoleType === 1) {
                pushRouterPath('/profile/company', { id: paramsObj });
                showToast("You've Successfully Logged in! Time to explore and thrive.", 'success');
            } else if (userDetails?.RoleType === 2) {
                pushRouterPath('/dashboard/employee', { id: paramsObj });
                showToast("You've Successfully Logged in! Time to explore and thrive.", 'success');
            } else if (userDetails?.RoleType === 3) {
                pushRouterPath('/platform-user/support', { id: paramsObj });
                showToast("You've Successfully Logged in! Time to explore and thrive.", 'success');
            } else if (userDetails?.RoleType === 4) {
                pushRouterPath('/platform-user/candidate', { id: paramsObj });
                showToast("You've Successfully Logged in! Time to explore and thrive.", 'success');
            } else if (userDetails?.RoleType === 5) {
                pushRouterPath('/platform-user/manager/organization', { id: paramsObj });
                showToast("You've Successfully Logged in! Time to explore and thrive.", 'success');
            } else {
                showToast('Unauthenticated User!', 'warning');
            }

            handleClear();
        } else if (resp && resp?.status == 402) {
            toast({
                title: resp.data.message,
                status: "error",
                duration: 3000,
                position: 'bottom-right',
                variant: 'left-accent',
                isClosable: true
            });
            setShowSpinner(false);
            identityVerificationModal.onOpen();
            return;
        }
        else {
            toast({
                title: resp.data.message,
                status: "error",
                duration: 3000,
                position: 'bottom-right',
                variant: 'left-accent',
                isClosable: true
            });
            setShowSpinner(false);
        }

    }

    const handlePasswordVisibility = () => setShowPassword(!showPassword);

    const handleClear = () => {
        setInitialValues({
            email: "",
            password: "",
        } as LoginModel);
        setShowPassword(false);
        setShowSpinner(false);
    }

    const handleRegister = () => {
        router.push("/register/candidate");
    };

    return (
        <>
            <PageLoader show={showSpinner} />
            <ForgotPasswordModal />
            <IdentityVerificationModal email={initialValues?.email} />
            <Stack spacing={4} w="full" p="2" justifyContent={"center"} marginInlineStart={"0px !important"}>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={loginValidation}
                    onSubmit={handleSubmit}
                >
                    <Form style={{ width: "100%" }}>
                        <Stack justifyContent={"center"} >
                            <Link href={userHome}>
                                <Logo src="/images/nested.png" />
                            </Link>
                        </Stack>
                        <Stack justifyContent={"center"} flexDirection={"column"} display={"grid"} mt={4} mb={4}>
                            <Caption2 fontWeight={"600"} display={"flex"} justifyContent={"center"}>Thank you for getting back to Nested</Caption2>
                            <Caption2 fontWeight={"600"} display={"flex"} justifyContent={"center"}>Let's access our best network for you.</Caption2>
                        </Stack>
                        <VStack mt={4} spacing={4} color="black" align="stretch" w="full">
                            <Stack direction="column" w="full">
                                <Field
                                    name="email">
                                    {({ field, meta }: FieldProps) => (
                                        <Input
                                            {...field}
                                            borderWidth="2px"
                                            placeholder="Email Address"
                                            autoFocus
                                            borderColor={meta.error && meta.touched ? useStyles.errorColor : useStyles.exactColor}
                                            value={initialValues?.email}
                                            onChange={(e) => {
                                                setInitialValues(prevState => ({
                                                    ...prevState, email: e.target.value
                                                }));
                                            }}
                                        />
                                    )}
                                </Field>
                                <ErrorMessageYup name="email" />
                            </Stack>
                            <Stack direction="column" w="full">
                                <Field name="password">
                                    {({ field, meta }: FieldProps) => (
                                        <InputGroup>
                                            <InputRightElement pointerEvents="all"
                                                onClick={handlePasswordVisibility}>
                                                {showPassword ? (
                                                    <AiOutlineEyeInvisible color="gray.300" size={16} />
                                                ) : (
                                                    <AiOutlineEye color="gray.300" size={16} />
                                                )}
                                            </InputRightElement>
                                            <Input
                                                {...field}
                                                borderColor={meta.error && meta.touched ? useStyles.errorColor : useStyles.exactColor}
                                                borderWidth="2px"
                                                placeholder="Password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={initialValues?.password}
                                                onChange={(e) => {
                                                    setInitialValues(prevState => ({
                                                        ...prevState, password: e.target.value
                                                    }));
                                                }}
                                            />
                                        </InputGroup>
                                    )}
                                </Field>
                                <ErrorMessageYup name="password" />
                            </Stack>
                            <Stack direction="column" w="auto" alignSelf={"center"}>
                                <ButtonPrimary w="186px"
                                    _focusVisible={{
                                        border: "2px solid",
                                        borderColor: "blue.500",
                                        boxShadow: "0 0 0 1px blue.500",
                                    }}
                                    type="submit"
                                >Log In</ButtonPrimary>
                            </Stack>
                            <Stack direction="column" w="auto" alignSelf={"center"}>
                                <Link
                                    _focusVisible={{
                                        border: "2px solid",
                                        borderColor: "blue.500",
                                        boxShadow: "0 0 0 1px blue.500",
                                    }}
                                    fontSize={"12px"}
                                    color={"#0ab1bc"}
                                    fontWeight={600}
                                    display={"flex"}
                                    justifyContent={"center"}
                                    cursor={"pointer"}
                                    onClick={() => {
                                        onOpen();
                                    }}
                                >{`Forgot your password?`}</Link>
                                <Link
                                    _focusVisible={{
                                        border: "2px solid",
                                        borderColor: "blue.500",
                                        boxShadow: "0 0 0 1px blue.500",
                                    }}
                                    fontSize={"12px"}
                                    color={"#0ab1bc"}
                                    cursor={"pointer"}
                                    fontWeight={600}
                                    display={"flex"}
                                    justifyContent={"center"}
                                    href="/register/candidate"
                                >{`Don't have an account? Sign Up`}</Link>
                            </Stack>
                        </VStack>
                    </Form>
                </Formik>
            </Stack>
        </>
    );
};
export default LoginForm;