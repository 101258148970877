import { Button, useToast, VStack } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import Modal from ".";
import { useModalContext } from "../../../hooks/useContext";
import ButtonPrimary from "../button/ButtonPrimary";
import PageLoader from "../page-loader/PageLoader";
import { Field, FieldProps, Form, Formik } from "formik";
import ErrorMessageYup from "../error-message/ErrorMessageWrapper";
import Stack from "../Stack";
import Input from "../form-control/Input";
import { useStyles } from "@/core/form-error";
import { Caption1, Caption2 } from "../typography";
import { HandleSubmit } from "../form-stepper";
import { forgotPasswordValidation } from "@/core/form-validaation/login";
import { getEmailTemplate } from "@/core/utility/emailService";
import { sendEmail } from "@/components/services/EmailService";
import { candidateEmailVerification } from "@/core/utility/emailService/templates";
import { CandidateProfile } from "@/core/models/candidate";
import usersService from "@/components/services/UserService";
import _ from "lodash";
import { toUTCDate } from "@/core/utility/common";

type CandidateEmailVerification = {
  email: string,
};

interface IdentityVerificationModalProps {
  email?: string;
}

const IdentityVerificationModal: FC<IdentityVerificationModalProps> = (props) => {
  const { email } = props;
  const { identityVerificationModal } = useModalContext();
  const { isOpen, onClose } = identityVerificationModal;
  const toast = useToast();
  const [showSpinner, setShowSpinner] = useState(false);
  const displayHeader = "Oops! It seems like you've not verified your email. Please confirm it. If you want us to resend it please click below. Please also check your spam folders.";

  const [initialValues, setInitialValues] = useState({} as CandidateEmailVerification);
  const SUPPORT_TEAM_EMAIL = process.env.NEXT_PUBLIC_SUPPORT_TEAM_EMAIL;
  let API_BASE_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

  if (typeof window !== "undefined") {
    // Client-side-only code
    API_BASE_URL = window.location.origin;
  }

  useEffect(() => {
    if (email) {
      setInitialValues(prevState => ({
        ...prevState, email: email
      }));
    }
  }, [email])

  const handleClear = () => {

    setInitialValues({
      email: "",
    } as CandidateEmailVerification);
    setShowSpinner(false);
  }

  const handleSubmit: HandleSubmit<CandidateEmailVerification> = async (values: CandidateEmailVerification) => {
    if (!values) return;

    setShowSpinner(true);

    const model = {
      emailId: values.email
    }

    let resp = await usersService.GetUserFilteredProfileByWalletAddress('token', model);
    if (resp && resp.status == 200) {
      let candidate = resp.data as CandidateProfile;
      if (candidate && !_.isEmpty(candidate)) {

        await sendVerificationEmailToCandidate(candidate);

        toast({
          title: "Please check your email to verify your account.",
          status: "success",
          duration: 3000,
          position: 'bottom-right',
          variant: 'left-accent',
          isClosable: true
        });
      } else {
        toast({
          title: "Your search did not return any results. Please try again with other information.",
          status: "error",
          duration: 3000,
          position: 'bottom-right',
          variant: 'left-accent',
          isClosable: true
        });
      }

      handleOnClickCancel();

    } else {
      toast({
        title: "Your search did not return any results. Please try again with other information.",
        status: "error",
        duration: 3000,
        position: 'bottom-right',
        variant: 'left-accent',
        isClosable: true
      });
      handleClear();
    }
  }

  const handleOnClickCancel = () => {
    handleClear();
    onClose();
  }

  const sendVerificationEmailToCandidate = async (values: CandidateProfile) => {
    //Send verification mail
    let fullName = `${values?.publicProfile?.firstName} ${values?.publicProfile?.lastName ?? ""}`;
    let mailOptions = {
      to: values?.publicProfile?.email ?? "",
      subject: candidateEmailVerification.subject,
      html: ""
    };

    var today = new Date();
    today.setMinutes(today.getMinutes() + 30);
    let expiryDate = toUTCDate(today);


    let emailDetail = {
      c_data: {
        e: values?.publicProfile?.email,
        i: values?.publicProfile?.userId
      },
      e_time: today
    }
    const empUniqueHash = Buffer.from(JSON.stringify(emailDetail)).toString('base64');

    let templateData = {
      name: fullName,
      contactEmail: SUPPORT_TEAM_EMAIL || "",
      link: `${API_BASE_URL}/verify-account/Idata:${empUniqueHash}`,
      emailExpiry: "30 minutes",
    }

    mailOptions.html = getEmailTemplate(candidateEmailVerification.body, templateData);

    let resp = await sendEmail(mailOptions);
  }

  return (
    <>
      <PageLoader show={showSpinner} />
      <Modal isOpen={isOpen} onClose={onClose} header="Email Not Verified!">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={forgotPasswordValidation}
          onSubmit={handleSubmit}
        >
          <Form>
            <Stack justifyContent={"start"} flexDirection={"column"} display={"grid"} mb={4}>
              <Caption2 fontWeight={"600"} display={"flex"} justifyContent={"flex-start"}>{displayHeader}</Caption2>
            </Stack>
            <VStack mt={4} spacing={4} color="black" align="stretch" w="full">
              <Stack direction="column" w="full">
                <Field
                  name="email">
                  {({ field, meta }: FieldProps) => (
                    <Input
                      {...field}
                      borderWidth="2px"
                      placeholder="Email Address*"
                      borderColor={meta.error ? useStyles.errorColor : useStyles.exactColor}
                      value={initialValues?.email}
                      onChange={(e) => {
                        setInitialValues(prevState => ({
                          ...prevState, email: e.target.value
                        }));
                      }}
                    />
                  )}
                </Field>
                <ErrorMessageYup name="email" />
              </Stack>
              <Stack flex={1} direction="row">
                <Button
                  mt="6"
                  bg="red.500"
                  w="186px"
                  onClick={() => handleOnClickCancel()}
                >
                  <Caption1 color="black">{"Cancel"}</Caption1>
                </Button>
                <ButtonPrimary mt="6" w="186px"
                  type="submit"
                >
                  Send
                </ButtonPrimary>
              </Stack>
            </VStack>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};
export default IdentityVerificationModal;
